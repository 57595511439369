import React from "react"
import { graphql, Link } from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

const HowPage = (props) => (
      <Layout>
        <SEO description="BuilderCover is the services concierge to building developers and owners. According to your project, we put our clients in touch with the highest performing vendors across many service industries."
        title="How It Works" 
        keywords={[`construction sevices`, `building services`, `construction vendors`, `construction insurance`, `construction finance`]} />
        <section className="hero is-small is-primary">
          <div className="hero-body">
            <div className="container">
              <div className="columns">
                <div className="column is-1 is-hidden-touch">
                </div>
                <div className="column is-8">
                  <h2 className="subtitle is-size-4 is-uppercase has-text-info">
                    How It Works
                  </h2>
                  <h1 className="title has-text-white is-size-1-desktop is-size-2-touch has-text-weight-bold">
                    Easily find the right help for your project.
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section has-background-light">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-5">
                <div>
                  <Img fluid={props.data.compImage.childImageSharp.fluid} alt="Submit Our Online Form"/>
                </div>
              </div>
              <div className="column is-1"></div>
              <div className="column is-6">
                <div>
                  <h2 className="is-size-2 has-text-weight-bold">Complete and Submit the Service Request Form</h2>
                  <p>
                    Our Service Request form is a simple submission of project and contact info. Most clients take
                    five minutes to complete a service request with us. We don't waste your time. We save it!
                  </p><br/>
                  <Link to="/request" className="button is-link is-rounded">Request Services Now</Link>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-6">
                <div>
                <h2 className="is-size-2 has-text-weight-bold">BuilderCover Matches the Best Service Partner with Your Project</h2>
                  <p>
                    BuilderCover uses proprietary methods to match you with a vendor that has been successful in projects similar to yours.
                    We filter vendors geographically, then assess your needs against our vendor network, using a mutitude of data points. 
                  </p>
                </div>
              </div>
              <div className="column is-1"></div>
              <div className="column is-5">
                <div>
                  <Img fluid={props.data.statsImage.childImageSharp.fluid} alt="BuilderCover Matching" />
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section has-background-light">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-5">
                <div>
                  <Img fluid={props.data.phoneImage.childImageSharp.fluid} alt="Construction Vendor Calling"/>
                </div>
              </div>
              <div className="column is-1"></div>
              <div className="column is-6">
                <div>
                  <h2 className="is-size-2 has-text-weight-bold">Selected Vendor Contacts You Directly</h2>
                  <p>
                    We match our clients with vendors on a one-to-one basis. A BuilderCover vendor will contact you directly,
                    using your preferred contact method. With BuilderCover, there is no worry of the phone ringing or emails
                    incoming at a dizzying pace. We drive business the way our clients do, with a handshake and smile.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section">
          <div className="container">
            <div className="columns is-vcentered">
              <div className="column is-6">
                <div>
                  <h2 className="is-size-2 has-text-weight-bold">Need Additional Services?<br/>Send Another Request.</h2>
                  <p>
                    If you need additional help at any time - pre, mid or post construction - complete our Service Request form or connect with a service rep to hasten the search for additional vendors. Otherwise, relax and enjoy the efficiency of construction with BuilderCover.
                  </p>
                </div>
              </div>
              <div className="column is-1"></div>
              <div className="column is-5">
                <div>
                  <Img fluid={props.data.relaxImage.childImageSharp.fluid} alt="Construction Project Complete"/>
                </div>
              </div>
            </div>
          </div>
        </section>
      </Layout>
    )

export default HowPage

export const pageQuery = graphql `
  query {
    compImage: file(relativePath: { eq: "how_comp.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    statsImage: file(relativePath: { eq: "how_stats.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    phoneImage: file(relativePath: { eq: "how_phone.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    relaxImage: file(relativePath: { eq: "how_relax.png"}) {
      childImageSharp {
        fluid(maxWidth: 700, quality: 70) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;